<template>
  <div class="content">
    <div class="friend-wrapper">
      <search></search>
      <friendlist
        :type="type"
        @handleClick="handleClick"
      ></friendlist>
    </div>
    <div class="friendinfo">
      <applyList v-if="type == 1"></applyList>
      <info v-show="type == 2"></info>
      <group
        :item="item"
        v-if="type == 3"
      ></group>
    </div>
  </div>
</template>

<script>
import search from '@/components/search/search'
import friendlist from '@/components/friendlist/friendlist'
import info from '@/components/info/info'
import applyList from '@/components/applyList'
import group from '@/components/group'
export default {
  components: {
    search,
    friendlist,
    info,
    group,
    applyList
  },
  data () {
    return {
      type: 0,
      item: null
    }
  },
  methods: {
    handleClick (type, item) {
      this.type = type
      if (type == 3) {
        this.item = item
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.content
  display flex
  .friend-wrapper
    width 250px
    background rgb(230, 230, 230)
  .friendinfo
    width 550px
    flex 1
</style>
